import React from 'react';
import Layout from 'components/Layout';
import { Container, Title, CardsList, Subtitle } from 'ui';
import { snakeCase } from 'lodash';
import { graphql } from 'gatsby';
import SkillCard from 'components/SkillCard';
import Seo from 'components/Seo';

const Skills = ({ data, location }) => {
  const initialSkill = location?.hash.split('#')[1];
  return (
    <Layout>
      <Seo
        title={data?.datoCmsSkillsPage?.seo?.title}
        description={data?.datoCmsSkillsPage?.seo?.description}
      />
      <Container variant="main">
        <Title>{data?.datoCmsSkillsPage?.title}</Title>
        <Subtitle>{data?.datoCmsSkillsPage?.description}</Subtitle>

        <CardsList>
          {(data?.allDatoCmsSkill?.edges || []).map(({ node }, index) => (
            <div id={snakeCase(node?.title)}>
              <SkillCard
                skill={node}
                key={index}
                isInitialSkill={initialSkill === snakeCase(node?.title)}
              />
            </div>
          ))}
        </CardsList>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    datoCmsSkillsPage {
      title
      description
      seo {
        title
        description
      }
    }
    allDatoCmsSkill {
      edges {
        node {
          id
          title
          icon {
            url
          }
          contentNode {
            childMarkdownRemark {
              excerptAst(pruneLength: 500)
              htmlAst
            }
          }
        }
      }
    }
  }
`;

export default Skills;
